import React, { useState, useEffect } from 'react'
import '../Register/Register.css'
import OAuth2Login from 'react-simple-oauth2-login';
import { GoogleLogin, useGoogleLogin } from '@react-oauth/google';
import { Outlet, useNavigate } from 'react-router-dom';
import { useCookies } from "react-cookie";
import { toast, ToastContainer } from 'react-toastify';
import { getErrorMessage } from '../../../utils/getError';
import { decodeToken } from '../../../utils/decodeToken';
import axios from 'axios';

const Login = () => {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const navigate = useNavigate('');
    const [cookies, setCookie, removeCookie] = useCookies(["cookie-name"]);

    const axiosInstance = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
    });

    useEffect(() =>{
        if (cookies.jwt){
            navigate('/home')
        }
    }, [cookies, navigate])

    const loginUser = async (e) => {
        e.preventDefault();
        try{
            const url = '/v1/users/login';
            const { data } = await axiosInstance.post(
            url,
            {
                email: email, password: password
            },
            {
                headers: { 'Access-Control-Allow-Origin': (process.env.DEVSTATUS === "Production" ? "https://api.cybersanctus.com" : "*")},
            }
            ); // data: res
            localStorage.setItem("jwt", data.data.token)
            if(data){
            if(data.errors){
                toast.error(getErrorMessage(data));
            } else {
                setCookie("jwt", data.data.token, { domain: '.cybersanctus.com' });
                navigate('/home');
            }
            }
        }catch(error){
            toast.error(getErrorMessage(error))
            if(error.response.status === 429){
                //setErrorData(error.response.data);
            }
        }
    }

    const onSuccessFacebook = async (res) => {
        try{
            const {access_token} = res;
            const response = await axiosInstance.post('/v1/users/facebook',
            {
                access_token
            },
            {
                headers: {'Access-Control-Allow-Origin': (process.env.DEVSTATUS === "Production" ? "https://api.cybersanctus.com" : "*")}
            }
            );
            setCookie("jwt", response.data.data.token, { domain: '.cybersanctus.com' });
            navigate('/home');
        }catch(err) {
            toast.error('An unexpected error occured, please try again later.');
        }
    }

    const onFailure = (res) => {
        toast.error(getErrorMessage(res));
    }

    const googleAuth = async ({credential}) => {
        try{
            console.log(credential)
            const decoded = decodeToken(credential);
            console.log(decoded)
            const response = await axiosInstance.post('/v1/users/google', {
                email: decoded.email,
                password: decoded.sub,
                profilePic: decoded.picture
            })
            setCookie("jwt", response.data.data.token, { domain: '.cybersanctus.com' });
            //console.log(response)
            navigate('/home')
        }catch(err) {
            toast.error(getErrorMessage(err));
        }
    }

    const googleLogin = useGoogleLogin({
        onSuccess: async ({ code }) => {
            try{
            const tokens = await axiosInstance.post('/v1/users/google/tokens', {
                code,
            });
        
            const decoded = decodeToken(tokens.data.id_token);
            const response = await axiosInstance.post('/v1/users/google', {
                email: decoded.email,
                password: decoded.sub,
                profilePic: decoded.picture
            })
            setCookie("jwt", response.data.data.token, { domain: '.cybersanctus.com' });
            //console.log(response)
            navigate('/home')
            }catch(err) {
            toast.error(getErrorMessage(err));
            }
            
        },
        onError: () => {
            toast.error("Login failed")
        },
        flow: 'auth-code',
    })
    
  return (
    <div className='register-container'>
        <ToastContainer />
        <div className='register-content'>
            <div className='register-header'>
                <h1>Login</h1>
                <p>Hello there! Sign in to your account now to resume your cybersecurity journey with us.</p>
                <div>
                    <GoogleLogin onSuccess={(creds) => googleAuth(creds)} onError={(error) => console.log(error)}/>
                </div>
            </div>
            <div className='span-separator'>
                <span></span>
                <p>or</p>
                <span></span>
            </div>
            <div className='register-fields'>
                <div className='register-field'>
                    <div>
                        <label><img src={require('./email.svg').default} />Enter Email</label>
                    </div>
                    <input type='email' placeholder='example@cybersanctus.com' value={email} onChange={event => setEmail(event.target.value)} />
                </div>
                <div className='register-field'>
                    <div>
                        <label><img src={require('./password.svg').default} />Password</label>
                    </div>
                    <input type='password' placeholder='**********' value={password} onChange={event => setPassword(event.target.value)} />
                </div>
            </div>
            <div className='register-actions'>
                <button className='register-button' onClick={(e) => loginUser(e)}>Sign in</button>
                <p className='register-offer'>Don't have an account? <span onClick={() => navigate('/register')} style={{cursor: 'pointer'}}>Register</span></p>
            </div>
        </div>
    </div>
  )
}

export default Login
